<template>
  <header
    :style="`background-color: ${backgroundColor}; color: #FFFFFF;`">
    <nav>
      <v-container>
        <v-row class="d-flex justify-space-between align-center">
          <v-col cols="2">
            <div class="text-left">
              <v-btn
                v-if="back"
                icon
                dark
                @click="goPatch()"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="8"
          >
            <div
              class="d-flex justify-center align-center"
              style="height: 52px">
              <!-- <div class="d-flex justify-center align-center"> -->
              <div
                style="font-size: 20px;">
                {{ title }}
              </div>
            </div>
          </v-col>
          <v-col cols="2">
            <!-- <div v-if="points">
              {{ $t('global.point') }} : {{ showPoint }}
            </div> -->
            <div
              v-if="language"
              class="d-flex justify-end align-center">
              <v-btn
                v-if="$i18n.locale === 'en'"
                class="ma-2"
                drak
                icon
                color="secondary"
                @click="changeLanguage('th')"
              >
                <img src="@/assets/thailand.png">
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                drak
                icon
                color="secondary"
                @click="changeLanguage('en')"
              >
                <img src="@/assets/england.png">
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </nav>
  </header>
</template>

<script>
import axios from '../utils/axios.js'

export default {
  data () {
    return {
      point: 0
    }
  },
  computed: {
    showPoint () {
      if (this.point) {
        return this.point
      }
      return 0
    },
    backgroundColor () {
      return '#3BAEF8'
    },
    title () {
      const { name } = this.$route
      switch (name) {
        case 'Register': return this.$t('global.register')
        case 'Atk': return this.$t('atk.atkTitle')
        case 'History': return this.$t('history.historyTitle')
        case 'History-ATK': return this.$t('atk.atkNone')
        case 'History-Detail': return this.$t('booking.bookingDetail')
        case 'History-Change': return this.$t('booking.changeSchedule')
        case 'Book': return this.$t('booking.bookingTitle')
        case 'Web-Register': return this.$t('global.register')
        case 'History-SendFile': return this.$t('booking.visitConsent')
        case 'Web-History': return this.$t('history.historyTitle')
        case 'Web-History-Detail': return this.$t('booking.bookingDetail')
        case 'Web-History-Change': return this.$t('booking.changeSchedule')
        case 'Web-Atk': return this.$t('atk.atkTitle')
        default: return 'PLAN TOYS'
      }
    },
    back () {
      const { name } = this.$route
      const route = ['Atk', 'History-Detail', 'History-Change', 'Web-History-Detail', 'Web-History-Change', 'Web-Atk', 'History-SendFile']
      const check = route.includes(name)
      if (!check) return false
      return true
    },
    language () {
      const { name } = this.$route
      const route = ['Web-Register', 'Web-Atk', 'Web-History', 'Web-History-Detail', 'Web-History-Change']
      const check = route.includes(name)
      if (!check) return false
      return true
    },
    points () {
      const { name } = this.$route
      const route = ['Web-Register', 'Web-Atk', 'Web-History', 'Web-History-Detail', 'Web-History-Change', 'Book', 'History-Detail', 'Atk', 'History', 'History-Change', 'History-SendFile']
      const check = route.includes(name)
      if (!check) return false
      return true
    }
  },
  async mounted () {
    await this.getMember()
  },
  methods: {
    async getMember () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        if (member) {
          const { data: { data } } = await axios(`/member/${member.memberId}`)
          if (data.member) {
            this.point = data.member?.point
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    changeLanguage (type) {
      this.$i18n.locale = type
    },
    goPatch () {
      const { name } = this.$route
      switch (name) {
        case 'Atk': this.$router.push('/liff/history')
          break
        case 'History-Detail': this.$router.push('/liff/history')
          break
        case 'History-Change': this.$router.push('/liff/history')
          break
        case 'Web-History-Detail': this.$router.push('/history')
          break
        case 'Web-History-Change': this.$router.push('/history')
          break
        case 'Web-Atk': this.$router.push('/history')
          break
        case 'History-SendFile': this.$router.push('/liff/history')
          break
        default:
          break
      }
    }
  }

}
</script>

<style lang="scss" scoped>
</style>
