<template>
  <v-app>
    <Header v-if="headerEnable" />
    <AppBar v-if="appbarEnable" />
    <v-main
      v-if="!appbarEnable"
    >
      <router-view />
    </v-main>
    <router-view
      v-else />
    <snackbar
      :value="snackbar.value"
      :message="snackbar.message"
      :type="snackbar.type"
      :closeable="snackbar.closeable" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppBar from '@/components/AppBar.vue'
import Header from '@/components/Header.vue'
import Snackbar from '@/components/alert/Snackbar.vue'

export default {
  name: 'App',
  components: {
    Header,
    AppBar,
    Snackbar
  },
  data () {
    return {
      forbidRoutes: [
        'Book',
        'BookingGroup',
        'Paid-Success',
        'Home',
        'ChangeLanguage',
        'Web-Booking',
        '2c2p',
        'Web-Booking-Group',
        'Web-History',
        'Web-History-Detail',
        'Web-History-Change',
        'Web-Atk',
        'Web-Register'
      ],
      appbarRoutes: [
        'Web-History',
        'Web-Booking',
        'Web-History-Detail',
        'Web-History-Change',
        'Web-Register',
        'Web-Atk',
        'Web-Booking-Group'
      ]
    }
  },
  computed: {
    ...mapGetters({
      snackbar: 'notification/snackbar',
      bookingForm: 'booking/form',
      isRegistered: 'booking/isRegistered',
      participantsStore: 'booking/participants',
      group: 'booking/group',
      isMemberStore: 'booking/isMember'
    }),
    headerEnable () {
      return !this.forbidRoutes.includes(this.$route.name)
    },
    appbarEnable () {
      return this.appbarRoutes.includes(this.$route.name)
    }
  },
  mounted () {
    this.closeErrorMessage()
    this.setIsFromGroup(false)
    this.setIsFromBookingNormal(false)
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage',
      setForm: 'booking/setForm',
      setParticipants: 'booking/setParticipants',
      setIsRegistered: 'booking/setIsRegistered',
      setLineUid: 'booking/setLineUid',
      setGroup: 'booking/setGroup',
      setIsMember: 'booking/setIsMember',
      setIsFromGroup: 'booking/setIsFromGroup',
      setIsFromBookingNormal: 'booking/setIsFromBookingNormal',
      setBranch: 'booking/setBranch'
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Prompt', sans-serif;
}

.v-main {
  width: 768px;
  margin: auto;
}
</style>
