import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import NotificationModule from './modules/notification'
import BookingModule from './modules/booking'
import WebExternalModule from './modules/web-external'
import BookingGroupModule from './modules/booking-group'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = new Vuex.Store({
  modules: {
    notification: NotificationModule,
    booking: BookingModule,
    webExternal: WebExternalModule,
    bookingGroup: BookingGroupModule
  },
  plugins: [vuexLocal.plugin]
})

export default store
