import { getApps, initializeApp } from 'firebase/app'
import { getAuth, FacebookAuthProvider, signInWithPopup, signOut } from 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAee0vldF9m6KLc1IzZ7QUsqaxltpLQHq0',
  authDomain: 'plantoys-ba368.firebaseapp.com',
  projectId: 'plantoys-ba368',
  storageBucket: 'plantoys-ba368.appspot.com',
  messagingSenderId: '912035344172',
  appId: '1:912035344172:web:80e9b6358a00ea73a04689',
  measurementId: 'G-GRNG8R0GTJ'
}

if (!getApps().length) {
  initializeApp(config)
}

const provider = new FacebookAuthProvider()
const auth = getAuth()

export default {
  provider,
  auth,
  signInWithPopup,
  signOut
}
