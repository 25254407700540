const defaultState = {
  branch: process.env.VUE_APP_BRANCH,
  form: {
    scheduleId: '',
    memberId: '',
    participant: null,
    branch: process.env.VUE_APP_BRANCH,
    type: 'normal'
  },
  participants: 1,
  isRegistered: false,
  lineUid: '',
  group: {
    member: {
      firstname: '',
      lastname: '',
      tel: ''
    },
    form: {
      date: '',
      time: '',
      ageRange: [],
      joinQty: ''
    },
    branch: ''
  },
  isMember: false,
  isFromGroup: false,
  isFromBookingNormal: false
}

const mutations = {
  SET_BRANCH (state, payload) {
    state.branch = payload
  },
  SET_FORM (state, payload) {
    state.form = payload
  },
  SET_PARTICIPANTS (state, payload) {
    state.participants = payload
  },
  SET_IS_REGISTERED (state, payload) {
    state.isRegistered = payload
  },
  SET_LINE_UID (state, payload) {
    state.lineUid = payload
  },
  SET_GROUP (state, payload) {
    state.group = payload
  },
  SET_IS_MEMBER (state, payload) {
    state.isMember = payload
  },
  SET_IS_FROM_GROUP (state, payload) {
    state.isFromGroup = payload
  },
  SET_IS_FROM_BOOKING_NORMAL (state, payload) {
    state.isFromBookingNormal = payload
  }
}

const actions = {
  setBranch ({ commit }, payload) {
    commit('SET_BRANCH', payload)
  },
  setForm ({ commit }, payload) {
    commit('SET_FORM', payload)
  },
  setParticipants ({ commit }, payload) {
    commit('SET_PARTICIPANTS', payload)
  },
  setIsRegistered ({ commit }, payload) {
    commit('SET_IS_REGISTERED', payload)
  },
  setLineUid ({ commit }, payload) {
    commit('SET_LINE_UID', payload)
  },
  setGroup ({ commit }, payload) {
    commit('SET_GROUP', payload)
  },
  setIsMember ({ commit }, payload) {
    commit('SET_IS_MEMBER', payload)
  },
  setIsFromGroup ({ commit }, payload) {
    commit('SET_IS_FROM_GROUP', payload)
  },
  setIsFromBookingNormal ({ commit }, payload) {
    commit('SET_IS_FROM_BOOKING_NORMAL', payload)
  }
}

const getters = {
  branch: (state) => state.branch,
  form: (state) => state.form,
  participants: (state) => state.participants,
  isRegistered: (state) => state.isRegistered,
  lineUid: (state) => state.lineUid,
  group: (state) => state.group,
  isMember: (state) => state.isMember,
  isFromGroup: (state) => state.isFromGroup,
  isFromBookingNormal: (state) => state.isFromBookingNormal
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
