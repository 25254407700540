export default {
  th: {
    global: {
      notEnoughPackage: 'แพ็กเกจไม่เพียงพอ',
      usePackage: 'ใช้แพ็กเกจ',
      foodAllergy: 'แพ้อาหาร',
      or: 'หรือ',
      backToHistory: 'กลับหน้าหลัก',
      price: 'ราคา',
      package: 'แพ็กเกจ',
      congenitalDisease: 'โรคประจำตัว',
      priceBeforeDiscount: 'ราคาก่อนลด',
      discountAdultAndChildren: 'ส่วนลดเด็ก - ผู้ใหญ่',
      discountPromotion: 'ส่วนลดโปรโมชั่น',
      discountPoint: 'ส่วนลดแต้ม',
      discountPackage: 'ส่วนลดแพ็กเกจ',
      notEnoughPoints: 'แต้มไม่เพียงพอ',
      pointsUsed: 'แต้มที่ใช้',
      amountToUse: 'จำนวนที่จะใช้',
      allParticipants: 'รวมทั้งหมด',
      usePoint: 'ใช้แต้ม',
      point: 'แต้ม',
      have: 'มี',
      showTime: 'รอบเวลา',
      amount: 'จำนวน',
      event: 'กิจกรรม',
      order: 'หมายเลขการจอง',
      atkStatusTrue: 'ส่งครบแล้ว',
      discount: 'ส่วนลด',
      atkStatusFalse: 'ยังส่งไม่ครบ',
      atkStatusDetail: 'สถานะการส่ง ATK/ประวัติการฉีดวัคซีน',
      atkStatusDetailShort: 'สถานะการส่ง ATK/การฉีดวัคซีน',
      atkDetail: 'รายละเอียดการส่ง ATK หรือประวัติการฉีดวัคซีน',
      checkout: 'ชำระเงิน',
      priceOfAdult: 'ราคารวม (ผู้ใหญ่)',
      priceOfYoung: 'ราคารวม (เด็ก)',
      summary: 'รวมทั้งสิ้น',
      click: 'คลิก',
      backToMain: 'ระบบกำลังพาท่านกลับไปหน้าหลัก หากระบบไม่นำทางกรุณา',
      paidSuccess: 'ชำระเงินสำเร็จ',
      notFoundOrder: 'ไม่พบรายการสั่งซื้อ',
      direction2c2p: 'กำลังนำทางไปยังหน้าจ่ายเงินของ 2C2P Payment . . .',
      changeLanguangeSuccess: 'ดำเนินการเปลี่ยนภาษาสำเร็จ',
      goToBooking: 'กลับหน้าการจอง',
      checkValidate: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง',
      submit: 'ตกลง',
      today: 'วันนี้',
      next: 'ถัดไป',
      blank: 'ว่าง',
      branch: 'สาขา',
      group: 'กลุ่ม',
      full: 'เต็ม',
      detail: 'รายละเอียด',
      contact: 'ข้อมูลผู้ติดต่อ',
      validate: 'กรุณากรอกข้อมูล',
      validateRules: {
        email: 'กรุณากรอกอีเมล',
        formatEmail: 'รูปแบบอีเมลไม่ถูกต้อง'
      },
      validateParticipant: 'กรุณากรอกจำนวนผู้เข้าร่วม',
      validateParticipant1: 'จำนวนผู้เข้าร่วมอย่างน้อย 1 คน',
      countPerson: 'จำนวนสมาชิกจะเข้าร่วม (รวมผู้ลงทะเบียน)',
      typeBooking: 'ประเภทการจอง',
      addToCalendar: 'บันทึกไปยังปฏิทิน',
      person: 'คน',
      rangeTime: 'ช่วงเวลา',
      adult: 'ผู้ใหญ่',
      child: 'เด็ก',
      status: 'สถานะ',
      register: 'สมัครสมาชิก',
      oldMember: 'สมาชิกเดิม',
      oldMemberNote: '(หากไม่มีกรุณาไม่ต้องเลือก)',
      participant: 'ผู้เข้าร่วม',
      login: 'เข้าสู่ระบบ',
      name: 'ชื่อ',
      lastname: 'นามสกุล',
      nickname: 'ชื่อเล่น',
      organizationName: 'ชื่อหน่วยงาน',
      age: 'อายุ',
      success: 'สำเร็จ',
      view: 'ดู',
      delete: 'ลบ',
      addImage: 'เพิ่มรูปภาพ',
      edit: 'แก้ไข',
      notFound: 'ไม่พบข้อมูล',
      atkInfo: 'ข้อมูล ATK/ประวัติการฉีดวัคซีน',
      sendAtk: 'ส่งหลักฐาน ATK หรือประวัติการฉีดวัคซีน',
      require: 'จำเป็น',
      optional: 'ถ้ามี',
      carLicensePlate: 'ทะเบียนรถ',
      birthDate: 'วัน เดือน ปีเกิด',
      email: 'อีเมล',
      sex: 'เพศ',
      date: 'วันที่',
      day: 'วัน',
      month: 'เดือน',
      monthItems: {
        jan: 'ม.ค.',
        feb: 'ก.พ.',
        mar: 'มี.ค.',
        apr: 'เม.ย.',
        may: 'พ.ค.',
        jun: 'มิ.ย.',
        jul: 'ก.ค.',
        aug: 'ส.ค.',
        sep: 'ก.ย.',
        oct: 'ต.ค.',
        nov: 'พ.ย.',
        dec: 'ธ.ค.'
      },
      time: 'เวลา',
      rangeTimeItems: {
        morning: 'เช้า',
        afternoon: 'บ่าย',
        evening: 'เย็น',
        allday: 'ทั้งวัน'
      },
      year: 'ปี',
      tel: 'หมายเลขโทรศัพท์',
      type: 'ประเภท',
      men: 'ชาย',
      women: 'หญิง',
      undefine: 'ไม่ระบุ',
      nationality: 'สัญชาติ',
      subDistrict: 'ตำบล',
      district: 'อำเภอ',
      province: 'จังหวัด',
      zipCode: 'รหัสไปรษณีย์',
      trang: 'ตรัง',
      sathon: 'สาทร',
      note: 'หมายเหตุ',
      warning: 'คำเตือน'
    },
    type: {
      normal: 'บุคคลธรรมดา',
      group: 'กลุ่ม',
      event: 'กิจกรรม'
    },
    ageGroup: {
      young: 'เด็ก',
      student: 'นักเรียน/นักศึกษา',
      adult: 'ผู้ใหญ่',
      senior: 'ผู้สูงอายุ'
    },
    register: {
      personalInformation: 'ข้อมูลส่วนตัว',
      participantTitle: 'สมาชิกในครอบครัวที่จะเข้าร่วมกิจกรรม'
    },
    booking: {
      book: 'จอง',
      bookingCustomer: 'ข้อมูลผู้เข้าร่วม',
      bookingTitle: 'จองวันเข้าร่วมกิจกรรม',
      bookingInfo: 'ข้อมูลผู้จอง',
      bookingDetail: 'รายละเอียดการจอง',
      bookingMoreDetail: 'รายละเอียดการจองเพิ่มเติม',
      bookingDate: 'วันที่จอง',
      bookingTime: 'เวลาที่จอง',
      participantTotal: 'จำนวนผู้เข้าร่วม',
      bookingSummary: 'สรุปการจอง',
      bookingGroup: 'จองแบบกลุ่ม',
      nextButtonClick: 'กรุณาเลือกวันและเวลาจอง',
      chooseBranch: 'เลือกสาขาและประเภทการจอง',
      chooseActivity: 'เลือกกิจกรรม',
      actType: {
        normal: 'บุคคลธรรมดา',
        allday: 'ทั้งวัน',
        group: 'จองแบบกลุ่ม',
        special: 'จองกิจกรรม'
      },
      validate: 'โปรดเลือกวันที่ให้ถูกต้อง',
      validateMax: 'จำนวนผู้เข้าร่วมมากกว่าจำนวนที่จองได้',
      slotMax: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      visitConsent: 'หนังสือขอเข้าเยี่ยมชมพื้นที่',
      visitConsentSend: 'ส่งหนังสือขอเข้าเยี่ยมชมพื้นที่',
      attachFile: 'แนบไฟล์',
      expectPdf: '*ไฟล์ PDF เท่านั้น',
      clear: 'เคลียร์',
      upload: 'อัพโหลด',
      changeSchedule: 'เปลี่ยนรอบการจอง',
      oldSchedule: 'รอบเดิม',
      newSchedule: 'รอบที่ต้องการเปลี่ยน',
      confirm: 'ตกลง',
      confirm2: 'ตกลง',
      otherSchedule: 'เปลี่ยนรอบอื่น'
    },
    history: {
      historyTitle: 'ประวัติการเข้าร่วมกิจกรรม',
      orderStatus: {
        waiting: 'รอคอนเฟิร์ม',
        pending: 'รอชำระเงิน',
        confirmed: 'คอนเฟิร์มแล้ว',
        paid: 'ชำระเงินแล้ว',
        canceled: 'ยกเลิก',
        rejected: 'ปฏิเสธ'
      }
    },
    atk: {
      atkNone: 'กิจกรรมที่ยังไม่ส่งผล ATK หรือหลักฐานการฉีดวัคซีน',
      atkTitle: 'ส่งผลตรวจ ATK หรือหลักฐานการฉีดวัคซีน'
    },
    packages: {
      package: 'แพ็กเกจ',
      packageName: 'ชื่อแพ็กเกจ',
      packagePrice: 'ราคา',
      packageDetail: 'รายละเอียดแพ็กเกจ',
      expired: 'หมดอายุ',
      balance: 'ยอดคงเหลือ',
      maximumPrice: 'ยอดสูงสุดที่ใช้ได้',
      status: 'สถานะ',
      available: 'พร้อมใช้งาน',
      notAvailable: 'ไม่สามารถใช้งานได้',
      totalHighlight: 'ยอดรวมสูงกว่ายอดสูงสุด',
      notFound: 'ไม่พบแพ็กเกจ'
    },
    province2: {
      'Bangkok': 'Bangkok',
      'Samut Prakan': 'Samut Prakan',
      'Nonthaburi': 'Nonthaburi',
      'Pathum Thani': 'Pathum Thani',
      'Phra Nakhon Si Ayutthaya': 'Phra Nakhon Si Ayutthaya',
      'Ang Thong': 'Ang Thong',
      'Loburi': 'Loburi',
      'Sing Buri': 'Sing Buri',
      'Chai Nat': 'Chai Nat',
      'Saraburi': 'Saraburi',
      'Chon Buri': 'Chon Buri',
      'Rayong': 'Rayong',
      'Chanthaburi': 'Chanthaburi',
      'Trat': 'Trat',
      'Chachoengsao': 'Chachoengsao',
      'Prachin Buri': 'Prachin Buri',
      'Nakhon Nayok': 'Nakhon Nayok',
      'Sa Kaeo': 'Sa Kaeo',
      'Nakhon Ratchasima': 'Nakhon Ratchasima',
      'Buri Ram': 'Buri Ram',
      'Surin': 'Surin',
      'Si Sa Ket': 'Si Sa Ket',
      'Ubon Ratchathani': 'Ubon Ratchathani',
      'Yasothon': 'Yasothon',
      'Chaiyaphum': 'Chaiyaphum',
      'Amnat Charoen': 'Amnat Charoen',
      'Nong Bua Lam Phu': 'Nong Bua Lam Phu',
      'Khon Kaen': 'Khon Kaen',
      'Udon Thani': 'Udon Thani',
      'Loei': 'Loei',
      'Nong Khai': 'Nong Khai',
      'Maha Sarakham': 'Maha Sarakham',
      'Roi Et': 'Roi Et',
      'Kalasin': 'Kalasin',
      'Sakon Nakhon': 'Sakon Nakhon',
      'Nakhon Phanom': 'Nakhon Phanom',
      'Mukdahan': 'Mukdahan',
      'Chiang Mai': 'Chiang Mai',
      'Lamphun': 'Lamphun',
      'Lampang': 'Lampang',
      'Uttaradit': 'Uttaradit',
      'Phrae': 'Phrae',
      'Nan': 'Nan',
      'Phayao': 'Phayao',
      'Chiang Rai': 'Chiang Rai',
      'Mae Hong Son': 'Mae Hong Son',
      'Nakhon Sawan': 'Nakhon Sawan',
      'Uthai Thani': 'Uthai Thani',
      'Kamphaeng Phet': 'Kamphaeng Phet',
      'Tak': 'Tak',
      'Sukhothai': 'Sukhothai',
      'Phitsanulok': 'Phitsanulok',
      'Phichit': 'Phichit',
      'Phetchabun': 'Phetchabun',
      'Ratchaburi': 'Ratchaburi',
      'Kanchanaburi': 'Kanchanaburi',
      'Suphan Buri': 'Suphan Buri',
      'Nakhon Pathom': 'Nakhon Pathom',
      'Samut Sakhon': 'Samut Sakhon',
      'Samut Songkhram': 'Samut Songkhram',
      'Phetchaburi': 'Phetchaburi',
      'Prachuap Khiri Khan': 'Prachuap Khiri Khan',
      'Nakhon Si Thammarat': 'Nakhon Si Thammarat',
      'Krabi': 'Krabi',
      'Phangnga': 'Phangnga',
      'Phuket': 'Phuket',
      'Surat Thani': 'Surat Thani',
      'Ranong': 'Ranong',
      'Chumphon': 'Chumphon',
      'Songkhla': 'Songkhla',
      'Satun': 'Satun',
      'Trang': 'Trang',
      'Phatthalung': 'Phatthalung',
      'Pattani': 'Pattani',
      'Yala': 'Yala',
      'Narathiwat': 'Narathiwat',
      'buogkan': 'buogkan'
    },
    province: {
      กรุงเทพมหานคร: 'กรุงเทพมหานคร',
      สมุทรปราการ: 'สมุทรปราการ',
      นนทบุรี: 'นนทบุรี',
      ปทุมธานี: 'ปทุมธานี',
      พระนครศรีอยุธยา: 'พระนครศรีอยุธยา',
      อ่างทอง: 'อ่างทอง',
      ลพบุรี: 'ลพบุรี',
      สิงห์บุรี: 'สิงห์บุรี',
      ชัยนาท: 'ชัยนาท',
      สระบุรี: 'สระบุรี',
      ชลบุรี: 'ชลบุรี',
      ระยอง: 'ระยอง',
      จันทบุรี: 'จันทบุรี',
      ตราด: 'ตราด',
      ฉะเชิงเทรา: 'ฉะเชิงเทรา',
      ปราจีนบุรี: 'ปราจีนบุรี',
      นครนายก: 'นครนายก',
      สระแก้ว: 'สระแก้ว',
      นครราชสีมา: 'นครราชสีมา',
      บุรีรัมย์: 'บุรีรัมย์',
      สุรินทร์: 'สุรินทร์',
      ศรีสะเกษ: 'ศรีสะเกษ',
      อุบลราชธานี: 'อุบลราชธานี',
      ยโสธร: 'ยโสธร',
      ชัยภูมิ: 'ชัยภูมิ',
      อำนาจเจริญ: 'อำนาจเจริญ',
      หนองบัวลำภู: 'หนองบัวลำภู',
      ขอนแก่น: 'ขอนแก่น',
      อุดรธานี: 'อุดรธานี',
      เลย: 'เลย',
      หนองคาย: 'หนองคาย',
      มหาสารคาม: 'มหาสารคาม',
      ร้อยเอ็ด: 'ร้อยเอ็ด',
      กาฬสินธุ์: 'กาฬสินธุ์',
      สกลนคร: 'สกลนคร',
      นครพนม: 'นครพนม',
      มุกดาหาร: 'มุกดาหาร',
      เชียงใหม่: 'เชียงใหม่',
      ลำพูน: 'ลำพูน',
      ลำปาง: 'ลำปาง',
      อุตรดิตถ์: 'อุตรดิตถ์',
      แพร่: 'แพร่',
      น่าน: 'น่าน',
      พะเยา: 'พะเยา',
      เชียงราย: 'เชียงราย',
      แม่ฮ่องสอน: 'แม่ฮ่องสอน',
      นครสวรรค์: 'นครสวรรค์',
      อุทัยธานี: 'อุทัยธานี',
      กำแพงเพชร: 'กำแพงเพชร',
      ตาก: 'ตาก',
      สุโขทัย: 'สุโขทัย',
      พิษณุโลก: 'พิษณุโลก',
      พิจิตร: 'พิจิตร',
      เพชรบูรณ์: 'เพชรบูรณ์',
      ราชบุรี: 'ราชบุรี',
      กาญจนบุรี: 'กาญจนบุรี',
      สุพรรณบุรี: 'สุพรรณบุรี',
      นครปฐม: 'นครปฐม',
      สมุทรสาคร: 'สมุทรสาคร',
      สมุทรสงคราม: 'สมุทรสงคราม',
      เพชรบุรี: 'เพชรบุรี',
      ประจวบคีรีขันธ์: 'ประจวบคีรีขันธ์',
      นครศรีธรรมราช: 'นครศรีธรรมราช',
      กระบี่: 'กระบี่',
      พังงา: 'พังงา',
      ภูเก็ต: 'ภูเก็ต',
      สุราษฎร์ธานี: 'สุราษฎร์ธานี',
      ระนอง: 'ระนอง',
      ชุมพร: 'ชุมพร',
      สงขลา: 'สงขลา',
      สตูล: 'สตูล',
      ตรัง: 'ตรัง',
      พัทลุง: 'พัทลุง',
      ปัตตานี: 'ปัตตานี',
      ยะลา: 'ยะลา',
      นราธิวาส: 'นราธิวาส',
      บึงกาฬ: 'บึงกาฬ'
    }
  },
  en: {
    global: {
      or: 'or',
      have: '',
      detail: 'Detail',
      package: 'Package',
      price: 'Price',
      backToHistory: 'Back to Main',
      foodAllergy: 'Food Allergy',
      congenitalDisease: 'Congenital Disease',
      notEnoughPackage: 'Not enough package',
      usePackage: 'Use Package',
      discountAdultAndChildren: 'Child - Adult discount',
      priceBeforeDiscount: 'Price before discount',
      discountPromotion: 'Discount Promotion',
      notEnoughPoints: 'Not Enough Points',
      pointsUsed: 'Points use',
      amountToUse: 'Amount to use',
      usePoint: 'Use Point',
      point: 'Point',
      showTime: 'Show Time ',
      event: 'Event',
      order: 'Order',
      discount: 'Discount',
      discountPoint: 'Discount Point',
      discountPackage: 'Discount Package',
      allParticipants: 'All Particapants',
      amount: 'Amount',
      atkStatusTrue: 'Complete',
      atkStatusFalse: 'Incomplete',
      atkDetail: 'ATK/Vaccination Detail',
      atkStatusDetail: 'ATK/Vaccination Status',
      atkStatusDetailShort: 'ATK/Vaccination Status',
      priceOfAdult: 'Total (Adult)',
      priceOfYoung: 'Total (Young)',
      summary: 'Summary',
      click: 'Click',
      checkout: 'Checkout',
      backToMain: 'The system is taking you back to the main page. If the system does not navigate, please',
      paidSuccess: 'Successful Payment',
      notFoundOrder: 'Order not found.',
      direction2c2p: 'Redirect to 2C2P Payment . . .',
      changeLanguangeSuccess: 'The language was been changed.',
      goToBooking: 'Back to booking Page',
      checkValidate: 'Please check your input.',
      agree: 'OK',
      submit: 'OK',
      next: 'Next',
      today: 'Today',
      branch: 'Branch',
      blank: 'Available',
      rangeTime: 'Time',
      person: 'Person',
      group: 'Group',
      adult: 'Adult',
      child: 'Young',
      sendAtk: 'Send ATK or Vaccination History',
      status: 'Status',
      success: 'Success',
      view: 'View',
      delete: 'Delete',
      notFound: 'Not Found',
      addImage: 'Add Image',
      edit: 'Edit',
      addToCalendar: 'Add to Calendar',
      typeBooking: 'Booking Type',
      oldMember: 'Registered Member',
      oldMemberNote: "(Add a new member don't check this box)",
      require: 'Required',
      contact: 'Contact',
      optional: 'Optional',
      validate: 'Please fill in the information',
      validateRules: {
        email: 'Please fill Email',
        formatEmail: 'Email format is wrong'
      },
      validateParticipant: 'Please fill participants',
      validateParticipant1: 'Participants must at least 1',
      countPerson: 'Number of members (include self)',
      register: 'Register',
      participant: 'Participant',
      login: 'Login',
      type: 'Type',
      atkInfo: 'ATK/Vaccination Information',
      full: 'Full',
      name: 'Name',
      lastname: 'Lastname',
      nickname: 'Nickname',
      organizationName: 'Organization Name',
      age: 'Age',
      birthDate: 'Birth Date',
      carLicensePlate: 'Car License Plate',
      email: 'Email',
      sex: 'Sex',
      date: 'Date',
      day: 'Day',
      month: 'Month',
      monthItems: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      },
      year: 'Year',
      time: 'Time',
      rangeTimeItems: {
        morning: 'Morning',
        afternoon: 'Afternoon',
        evening: 'Evening',
        allday: 'All Day'
      },
      tel: 'Tel',
      men: 'Male',
      women: 'Female',
      undefine: 'Undefined',
      nationality: 'Nationality',
      subDistrict: 'Sub District',
      district: 'District',
      province: 'Province',
      zipCode: 'Zip Code',
      trang: 'Trang',
      sathon: 'Sathorn',
      note: 'Note',
      warning: 'Warning'
    },
    type: {
      normal: 'Individual',
      group: 'Group',
      event: 'Event'
    },
    ageGroup: {
      young: 'Young',
      student: 'Student',
      adult: 'Adult',
      senior: 'Senior'
    },
    register: {
      personalInformation: 'Personal Infomation',
      participantTitle: 'Particapant in the family that will join the activity'
    },
    booking: {
      book: 'book',
      bookingTitle: 'Booking',
      bookingCustomer: 'Booking Customer',
      bookingInfo: 'Booking Info',
      bookingDetail: 'Booking Detail',
      bookingDate: 'Booking Date',
      bookingTime: 'Booking Time',
      bookingSummary: 'Summary',
      bookingGroup: 'Group Booking',
      bookingMoreDetail: 'Booking Detail',
      participantTotal: 'Total Participant',
      nextButtonClick: 'Please choose date and time',
      chooseBranch: 'Choose Branch and Booking Types',
      chooseActivity: 'Choose Activity',
      actType: {
        normal: 'Regular ticket',
        allday: 'All Day',
        group: 'Group Booking',
        special: 'Event/Activity'
      },
      validate: 'Please select date completely',
      validateMax: 'Participant more than available slot',
      slotMax: 'There was an error, Please try again.',
      visitConsent: 'Visit Consent Form',
      visitConsentSend: 'Send Visit Consent Form',
      attachFile: 'Attach File',
      expectPdf: '*Expect file PDF only',
      clear: 'Clear',
      upload: 'Upload',
      changeSchedule: 'Change Schedule',
      oldSchedule: 'Present Schedule',
      newSchedule: 'New Schedule',
      confirm: 'OK',
      confirm2: 'OK',
      otherSchedule: 'Other Schedule'
    },
    history: {
      historyTitle: 'History',
      orderStatus: {
        waiting: 'Waiting',
        pending: 'Pending',
        paid: 'Paid',
        confirmed: 'Confirmed',
        canceled: 'Canceled',
        rejected: 'Rejected'
      }
    },
    atk: {
      atkNone: 'No ATK/Vaccination Send',
      atkTitle: 'ATK or Vaccination History'
    },
    packages: {
      package: 'Package',
      packageName: 'Package Name',
      packagePrice: 'Package Price',
      packageDetail: 'Package Detail',
      expired: 'Expired',
      balance: 'Balance',
      maximumPrice: 'Maximum Price',
      status: 'Status',
      available: 'Available',
      notAvailable: 'Not Available',
      totalHighlight: 'The total is higher than the maximum.',
      notFound: 'Not found Packages.'
    },
    province: {
        กรุงเทพมหานคร: 'Bangkok',
        สมุทรปราการ: 'Samut Prakan',
        นนทบุรี: 'Nonthaburi',
        ปทุมธานี: 'Pathum Thani',
        พระนครศรีอยุธยา: 'Phra Nakhon Si Ayutthaya',
        อ่างทอง: 'Ang Thong',
        ลพบุรี: 'Loburi',
        สิงห์บุรี: 'Sing Buri',
        ชัยนาท: 'Chai Nat',
        สระบุรี: 'Saraburi',
        ชลบุรี: 'Chon Buri',
        ระยอง: 'Rayong',
        จันทบุรี: 'Chanthaburi',
        ตราด: 'Trat',
        ฉะเชิงเทรา: 'Chachoengsao',
        ปราจีนบุรี: 'Prachin Buri',
        นครนายก: 'Nakhon Nayok',
        สระแก้ว: 'Sa Kaeo',
        นครราชสีมา: 'Nakhon Ratchasima',
        บุรีรัมย์: 'Buri Ram',
        สุรินทร์: 'Surin',
        ศรีสะเกษ: 'Si Sa Ket',
        อุบลราชธานี: 'Ubon Ratchathani',
        ยโสธร: 'Yasothon',
        ชัยภูมิ: 'Chaiyaphum',
        อำนาจเจริญ: 'Amnat Charoen',
        หนองบัวลำภู: 'Nong Bua Lam Phu',
        ขอนแก่น: 'Khon Kaen',
        อุดรธานี: 'Udon Thani',
        เลย: 'Loei',
        หนองคาย: 'Nong Khai',
        มหาสารคาม: 'Maha Sarakham',
        ร้อยเอ็ด: 'Roi Et',
        กาฬสินธุ์: 'Kalasin',
        สกลนคร: 'Sakon Nakhon',
        นครพนม: 'Nakhon Phanom',
        มุกดาหาร: 'Mukdahan',
        เชียงใหม่: 'Chiang Mai',
        ลำพูน: 'Lamphun',
        ลำปาง: 'Lampang',
        อุตรดิตถ์: 'Uttaradit',
        แพร่: 'Phrae',
        น่าน: 'Nan',
        พะเยา: 'Phayao',
        เชียงราย: 'Chiang Rai',
        แม่ฮ่องสอน: 'Mae Hong Son',
        นครสวรรค์: 'Nakhon Sawan',
        อุทัยธานี: 'Uthai Thani',
        กำแพงเพชร: 'Kamphaeng Phet',
        ตาก: 'Tak',
        สุโขทัย: 'Sukhothai',
        พิษณุโลก: 'Phitsanulok',
        พิจิตร: 'Phichit',
        เพชรบูรณ์: 'Phetchabun',
        ราชบุรี: 'Ratchaburi',
        กาญจนบุรี: 'Kanchanaburi',
        สุพรรณบุรี: 'Suphan Buri',
        นครปฐม: 'Nakhon Pathom',
        สมุทรสาคร: 'Samut Sakhon',
        สมุทรสงคราม: 'Samut Songkhram',
        เพชรบุรี: 'Phetchaburi',
        ประจวบคีรีขันธ์: 'Prachuap Khiri Khan',
        นครศรีธรรมราช: 'Nakhon Si Thammarat',
        กระบี่: 'Krabi',
        พังงา: 'Phangnga',
        ภูเก็ต: 'Phuket',
        สุราษฎร์ธานี: 'Surat Thani',
        ระนอง: 'Ranong',
        ชุมพร: 'Chumphon',
        สงขลา: 'Songkhla',
        สตูล: 'Satun',
        ตรัง: 'Trang',
        พัทลุง: 'Phatthalung',
        ปัตตานี: 'Pattani',
        ยะลา: 'Yala',
        นราธิวาส: 'Narathiwat',
        บึงกาฬ: 'buogkan'
    }
  }
}
