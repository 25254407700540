import Vue from 'vue'
import dayjs from 'dayjs'
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
// import customParseFormat from "dayjs/plugin/customParseFormat";

import buddhistEra from 'dayjs/plugin/buddhistEra'
import 'dayjs/locale/th'
import 'dayjs/locale/en'

dayjs.extend(buddhistEra)

// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(customParseFormat);
Vue.prototype.$dayjs = dayjs

Vue.filter('formatMiniDate', (val) => dayjs(val).locale('th').format('DD/MM/BBBB'))

Vue.filter('formatDate', (val) => dayjs(val).locale('th').format('DD MMMM BBBB HH:mm:ss'))

Vue.filter('formatDateTime', (val) => dayjs(val).locale('th').format('DD MMMM BBBB HH:mm'))

Vue.filter('formatDateEn', (val) => dayjs(val).locale('en').format('DD MMMM YYYY'))

Vue.filter('formatDateTimeEn', (val) => dayjs(val).locale('en').format('DD MMMM YYYY h:mm A'))

Vue.filter('formatAge', (val) => dayjs(new Date()).diff(dayjs(val), 'year'))

Vue.filter('checkExpired', (val) => dayjs(new Date()).diff(dayjs(val), 'm'))

Vue.filter('formatCurrency', (val) => val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
