import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueI18n from 'vue-i18n'
import Toast from 'vue-toastification'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import './plugins/filter'
import './plugins/day'
import '@/plugins/Prototype'
import '@/plugins/firebase'

import 'vue-toastification/dist/index.css'

import messages from '../i18n.js'

Vue.config.productionTip = false
const options = {}

Vue.use(Toast, options)
Vue.use(VueAxios, axios)
Vue.use(VueI18n)

let language = localStorage.getItem('language') || 'th'

if (process.env.VUE_APP_BRANCH === 'sathorn') {
  language = 'en'
}

const i18n = new VueI18n({
  locale: language,
  messages
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
