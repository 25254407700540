const defaultState = {
  facebookId: ''
}

const mutations = {
  SET_FACEBOOK_ID (state, payload) {
    state.facebookId = payload
  }
}

const actions = {
  setFacebookId ({ commit }, payload) {
    commit('SET_FACEBOOK_ID', payload)
  }
}

const getters = {
  facebookId: (state) => state.facebookId
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
