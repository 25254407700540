const defaultState = {
  form: {
    scheduleId: '',
    memberId: '',
    participants: null,
    branch: process.env.VUE_APP_BRANCH,
    organizationName: '',
    contactFirstname: '',
    contactLastname: '',
    contactTel: '',
    contactEmail: '',
    note: '',
    verifyNote: ''
  },
  schedule: {
    slot: 0,
    date: '',
    startTime: '',
    endTime: '',
    max: 0
  },
  isRegistered: false,
  lineUid: '',
  isMember: false,
  isFromGroup: false
}

const mutations = {
  SET_BRANCH (state, payload) {
    state.branch = payload
  },
  SET_FORM (state, payload) {
    state.form = payload
  },
  SET_IS_REGISTERED (state, payload) {
    state.isRegistered = payload
  },
  SET_LINE_UID (state, payload) {
    state.lineUid = payload
  },
  SET_IS_MEMBER (state, payload) {
    state.isMember = payload
  },
  SET_IS_FROM_GROUP (state, payload) {
    state.isFromGroup = payload
  },
  SET_SCHEDULE (state, payload) {
    state.schedule = payload
  }
}

const actions = {
  setBranch ({ commit }, payload) {
    commit('SET_BRANCH', payload)
  },
  setForm ({ commit }, payload) {
    commit('SET_FORM', payload)
  },
  setIsRegistered ({ commit }, payload) {
    commit('SET_IS_REGISTERED', payload)
  },
  setLineUid ({ commit }, payload) {
    commit('SET_LINE_UID', payload)
  },
  setIsMember ({ commit }, payload) {
    commit('SET_IS_MEMBER', payload)
  },
  setIsFromGroup ({ commit }, payload) {
    commit('SET_IS_FROM_GROUP', payload)
  },
  setSchedule ({ commit }, payload) {
    commit('SET_SCHEDULE', payload)
  }
}

const getters = {
  branch: (state) => state.branch,
  form: (state) => state.form,
  isRegistered: (state) => state.isRegistered,
  lineUid: (state) => state.lineUid,
  isMember: (state) => state.isMember,
  isFromGroup: (state) => state.isFromGroup,
  schedule: (state) => state.schedule
}

export default {
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
