import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const guardMyRoute = async (to, from, next) => {
  if (localStorage.getItem('member')) {
    next()
  } else {
    next('/liff/register')
  }
}

const guardWeb = async (to, from, next) => {
  if (localStorage.getItem('facebookLogin')) {
    next()
  } else {
    next('/')
  }
}

const routes = [
  {
    path: '/',
    // redirect: { name: 'Register' }
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/clear/localstorage',
    // redirect: { name: 'Register' }
    name: 'ClearLocal',
    component: () => import('@/views/ClearLocalStorage.vue')
  },
  {
    path: '/api/change/liff',
    name: 'ChangeLanguage',
    component: () => import('@/views/api/change.vue')
  },
  {
    path: '/liff/booking',
    name: 'Book',
    component: () => import('@/views/Book/index.vue')
  },
  {
    path: '/2c2p/:orderId',
    name: '2c2p',
    component: () => import('@/views/2C2P/2c2p.vue')
  },
  {
    path: '/liff/booking/group/:branch',
    name: 'BookingGroup',
    component: () => import('@/views/Book/group/index.vue')
  },
  {
    path: '/liff/register',
    name: 'Register',
    component: () => import('@/views/Register/index.vue')
  },
  {
    path: '/liff/atk/:orderId',
    name: 'Atk',
    component: () => import('@/views/Atk/index.vue')
  },
  {
    path: '/liff/history',
    name: 'History',
    component: () => import('@/views/History/index.vue')
  },
  {
    path: '/liff/history/atk',
    name: 'History-ATK',
    component: () => import('@/views/History/pages/Atk/index.vue')
  },
  {
    path: '/liff/history/file/:orderId',
    name: 'History-SendFile',
    component: () => import('@/views/History/pages/History-detail/send-file.vue')
  },
  {
    path: '/liff/history/:orderId',
    name: 'History-Detail',
    component: () => import('@/views/History/pages/History-detail/index.vue')

  },
  {
    path: '/liff/history/change/:orderId',
    name: 'History-Change',
    component: () => import('@/views/History/pages/Change-schedule/index.vue')
  },
  {
    path: '/paid/success',
    name: 'Paid-Success',
    component: () => import('@/views/PaidSuccess.vue')
  },
  {
    path: '/booking',
    name: 'Web-Booking',
    component: () => import('@/views/External/Book/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/booking/group/:branch',
    name: 'Web-Booking-Group',
    component: () => import('@/views/External/Book/group/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/register',
    name: 'Web-Register',
    component: () => import('@/views/External/Register/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/history',
    name: 'Web-History',
    component: () => import('@/views/External/History/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/history/:orderId',
    name: 'Web-History-Detail',
    component: () => import('@/views/External/History/pages/History-detail/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/history/file/:orderId',
    name: 'Web-History-Detail',
    component: () => import('@/views/External/History/pages/History-detail/send-file.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/history/change/:orderId',
    name: 'Web-History-Change',
    component: () => import('@/views/External/History/pages/Change-schedule/index.vue'),
    beforeEnter: guardWeb
  },
  {
    path: '/atk/:orderId',
    name: 'Web-Atk',
    component: () => import('@/views/External/Atk/index.vue'),
    beforeEnter: guardMyRoute
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
