<template>
  <div>
    <div>
      <v-app-bar
        color="primary"
        dark
      >
        <v-app-bar-nav-icon
          v-if="isMobile"
          @click.stop="drawer2 = !drawer2"></v-app-bar-nav-icon>
        <v-toolbar-title
          v-if="!isMobile"
          class="mr-6">
          PlanToys
        </v-toolbar-title>
        <div
          v-if="!isMobile"
          class="d-flex justify-space-between">
          <div>
            <v-btn
              v-for="(item,index) in items"
              :key="index"
              text
              :to="item.path"
              class="mr-4">
              <v-icon class="mr-2">
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-btn>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="this.$i18n.locale === 'en'"
          large
          text
          @click="changeLanguage('th')"
        >
          EN
        </v-btn>
        <v-btn
          v-if="this.$i18n.locale === 'th'"
          large
          text
          @click="changeLanguage('en')"
        >
          TH
        </v-btn>
        <v-menu
          v-if="facebookInfo"
          bottom
          min-width="200px"
          rounded
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              x-large
              v-on="on"
            >
              <v-avatar
                color="brown"
                size="48"
              >
                <!-- <v-img src="https://cdn.dribbble.com/users/1786866/screenshots/13992097/media/c461eeae4d4b523c9d3bab7c66264916.png?compress=1&resize=400x300"></v-img> -->
                <v-img :src="facebookInfo.photoURL"></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar>
                  <v-img :src="facebookInfo.photoURL"></v-img>
                </v-avatar>
                <h3 class="mt-4">
                  {{ facebookInfo.displayName }}
                </h3>
                <p class="text-caption mt-1">
                  {{ facebookInfo.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <!-- <v-btn
                depressed
                rounded
                text
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider> -->
                <v-btn
                  v-if="!isMobile"
                  depressed
                  rounded
                  text
                  @click="logoutFacebook()"
                >
                  <v-icon>mdi-logout-variant</v-icon>
                  ออกจากระบบ
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer
        v-if="isMobile"
        v-model="drawer2"
        absolute
        dark
        color="primary"
        temporary
      >
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <p>{{ item.title }}</p>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            link
            @click="logoutFacebook()"
          >
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <p>ออกจากระบบ</p>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        drawer: true,
        drawer2: false
      }
    },
    computed: {
    isMobile () {
      if (this.$vuetify.breakpoint.width > 960) {
        return false
      }
        return true
    },
    items () {
      return [
            { title: this.$t('booking.bookingTitle'), path: '/booking', icon: 'mdi-book-cross' },
            { title: this.$t('history.historyTitle'), path: '/history', icon: 'mdi-clipboard-text-clock' }
          ]
    },
    facebookInfo () {
      const facebookInfo = JSON.parse(localStorage.getItem('facebookInfo'))
      if (facebookInfo) {
        return facebookInfo
      }
      return null
    }
    },
     methods: {
      changeLanguage (language) {
        localStorage.setItem('language', language)
        this.$i18n.locale = language
        this.$router.go(0)
      },
      onLogout () {
        this.$cookies.remove('token')
        this.$router.push('/login')
      },
      logoutFacebook () {
        localStorage.removeItem('facebookInfo')
        localStorage.removeItem('member')
        localStorage.removeItem('facebookLogin')
        this.$router.push('/')
      }
    }
  }
</script>
<style scoped>
.v-list-item--active {
  color: black !important;
  background-color: #fff;
}
.v-list-item--active::before {
  opacity: 0 !important;
}
</style>
