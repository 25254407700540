import Vue from 'vue'

Vue.filter('formatStatus', (val) => {
  switch (val) {
    case 'waiting':
      return 'รอคอนเฟิร์ม'
    case 'pending':
      return 'รอชำระเงิน'
    case 'paid':
      return 'ชำระเงินแล้ว'
    case 'canceled':
      return 'ยกเลิก'
    case 'rejected':
      return 'ปฏิเสธ'
    default:
      return ''
  }
})
